<template>
    <div class="page" id="reservationSetting">
        <el-form :model="basicSetForm" ref="basicSetForm" :rules="rules" label-width="180px" class="query-form">
            <el-row>
                <el-col :span="24" class="plateTit">基本设置</el-col>
                <el-col :span="12">
                    <el-form-item label="提前可预约天数：" prop="ticketingMakeDays">
                        <el-input size="small" v-model="basicSetForm.ticketingMakeDays"
                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                                  oninput="if(value>365)value=365;if(value<1)value=0" placeholder="提前预约天数"
                                  maxlength="3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="订单预约人数：" prop="ticketingOrderPeople">
                        <el-input size="small" v-model="basicSetForm.ticketingOrderPeople"
                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                                  oninput="if(value>15)value=15;if(value<1)value=1" placeholder="每个订单预约人数上限"
                                  maxlength="2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="参观人添加数量：" prop="ticketingVisitorUserMax">
                        <el-input size="small" v-model="basicSetForm.ticketingVisitorUserMax"
                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                                  oninput="if(value>15)value=15;if(value<1)value=1"
                                  placeholder="用户添加联系人数量上限" maxlength="2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="每人每天预约次数：" prop="ticketingMakeNum">
                        <el-col :span="12">
                            <el-select size="small" v-model="basicSetForm.ticketingMakeNum" placeholder="请选择"
                                       @change="changeTicketingMakeNum">
                                <el-option
                                        v-for="item in makeNumOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="12" v-show="basicSetForm.ticketingMakeNum == 2">
                            <el-input size="small" v-model="ticketingMakeNum"
                                      oninput="if(value>10)value=10;if(value<1)value=1"
                                      onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入次数（≤10）"
                                      maxlength="2"></el-input>
                        </el-col>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="预约核销：" prop="ticketingCheckTime">
                        <el-radio-group v-model="basicSetForm.ticketingCheckTime">
                            <el-radio label="1">精确到日期</el-radio>
                            <el-radio label="0">精确到时间段</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item>
                        <el-button type="primary" size="small" v-noMoreClick @click="submitBasicSet">提交</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="query-form">
            <div class="plateTit">放票规则</div>
            <!--默认放票规则-->
            <el-form size="small" :model="defaultRulesForm" ref="defaultRulesForm" label-width="0px"
                     class="ticketsRulesBox">
                <el-row>
                    <el-col :span="3" class="itemLabel">默认放票规则：</el-col>
                    <el-col :span="21">
                        <el-col class="b_d flex_b_c p_all1" v-show="!defaultRulesIsEdit" style="width: 42.9%">
                            <div class="fontSize14">
                                <div class="p_b1" v-for="(item, index) in defaultRulesForm.list" :key="index">
                                    {{item.startTime}}~{{item.endTime}} {{item.countTicketing}}（张票）
                                </div>
                            </div>
                            <el-button type="text" icon="el-icon-edit" @click="editDefaultRules">修改</el-button>
                        </el-col>
                        <el-col :span="24" v-show="defaultRulesIsEdit">
                            <el-col :span="24" v-for="(item, index) in defaultRulesForm.list" :key="index">
                                <el-col :span="4">
                                    <el-form-item :prop="`list.${index}.startTime`"
                                                  :rules="[{required: true, message: '开始时段不能为空', trigger: 'change'}]">
                                        <el-time-select v-model="item.startTime" size="small"
                                                        :picker-options="{start: index == 0 ? '09:00' : defaultRulesForm.list[index-1].endTime, step: '00:30',end: '17:00',maxTime: item.endTime}"
                                                        placeholder="请选择开始时段" style="width: 100%"></el-time-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="1" class="text_center">~</el-col>
                                <el-col :span="4">
                                    <el-form-item :prop="`list.${index}.endTime`"
                                                  :rules="[{required: true, message: '结束时段不能为空', trigger: 'change'}]">
                                        <el-time-select v-model="item.endTime" size="small"
                                                        :picker-options="{start: '09:00',step: '00:30',end: '17:00',minTime: item.startTime}"
                                                        placeholder="请选择结束时段" style="width: 100%"></el-time-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4" class="m_l1">
                                    <el-form-item :prop="`list.${index}.countTicketing`"
                                                  :rules="[{required: true, message: '放票数量不能为空', trigger: 'blur'}]">
                                        <el-input size="small" v-model="item.countTicketing"
                                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                                                  @input="countTicketingChange(item)"
                                                  placeholder="请输入放票数量" maxlength="5"></el-input>
                                    </el-form-item>

                                </el-col>
                                <el-col :span="3" class="m_l1">
                                    <el-button size="medium" type="text" icon="el-icon-minus"
                                               v-show="defaultRulesForm.list.length > 1"
                                               @click="delDefaultRulesItem(index)"></el-button>
                                    <el-button size="medium" type="text" icon="el-icon-plus"
                                               v-show="defaultRulesForm.list.length < 10 && item.startTime != '' &&  item.endTime != '' && index == defaultRulesForm.list.length - 1"
                                               @click="addDefaultRulesItem"></el-button>
                                </el-col>
                            </el-col>
                            <div class="tips p_b1">
                                <div>* 修改时间段时，已预约的票数作为一个单独的票仓存储。已预约数据保持不变。</div>
                                <div>仅修改票数时，不得低于已预约的数量。如，已预约10人，设置放票数量时，需大于等于10。</div>
                            </div>
                            <el-button size="small" type="primary" @click="submitDefaultRules" v-noMoreClick>提交
                            </el-button>
                            <el-button size="small" @click="cancelDefaultRules">取消</el-button>
                        </el-col>
                    </el-col>
                </el-row>
            </el-form>
            <!--自定义放票规则-->
            <el-row class="p_t2 p_b2 customRules">
                <el-col :span="3" class="itemLabel">自定义放票规则：</el-col>
                <el-col :span="9">
                    <el-calendar v-model="customRulesCalendarVal">
                        <template slot="dateCell" slot-scope="{date, data}">
                            <div @click.stop="data.type != 'prev-month' ? chooseCustomRulesDay(data):''"
                                 class="dayItem">
                                <div>{{ data.day.split('-').slice(2)[0] }}</div>
                                <div v-for="(item, index) in formatCustomRules(data, 0)" :key="index">
                                    <div class="dayVotesNum" v-show="data.type == 'current-month'">
                                        {{item.outTicketing}}/{{item.countTicketing}}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-calendar>
                </el-col>
                <el-col :span="12" v-show="showCustomRules">
                    <el-form size="small" :model="customRulesForm" ref="customRulesForm" label-width="120px"
                             class="ticketsRulesBox p_t2" :disabled="!customRulesIsEdit">
                        <el-form-item label="放票规则：" prop="checkOperate">
                            <el-radio-group v-model="customRulesForm.checkOperate" @input="checkOperateChange">
                                <el-radio label="0">默认</el-radio>
                                <el-radio label="1">自定义</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="设置日期：" prop="belongToDay"
                                      :rules="[{required: true, message: '日期不能为空', trigger: 'change'}]">
                            <el-date-picker v-if="customRulesIsEdit"
                                            v-model="customRulesForm.belongToDay"
                                            :picker-options="pickerOptions"
                                            type="daterange"
                                            value-format="yyyy-MM-dd"
                                            format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                            <div v-else>{{customRulesForm.belongToDay[0]}} ~ {{customRulesForm.belongToDay[1]}}</div>
                        </el-form-item>
                        <div v-show="customRulesForm.checkOperate == 1">
                            <el-form-item label="放票数量：" :rules="[{required: true}]" v-if="!customRulesIsEdit">
                                <el-col :span="17" class="fontSize14">
                                    <div class="p_b1"
                                         v-for="(item, index) in customRulesForm.ticketingStorehouseDetailVoList"
                                         :key="index">{{item.storehouseStartTime}}~{{item.storehouseEndTime}}
                                        {{item.countTicketing}}（张票）
                                    </div>
                                </el-col>
                            </el-form-item>
                            <el-row v-for="(item, index) in customRulesForm.ticketingStorehouseDetailVoList"
                                    :key="index" v-if="customRulesIsEdit">
                                <el-col :span="10">
                                    <el-form-item :label="index == 0 ? '放票数量：':''"
                                                  :prop="`ticketingStorehouseDetailVoList.${index}.storehouseStartTime`"
                                                  :rules="[{required: true, message: '开始时段不能为空', trigger: 'change'}]">
                                        <el-time-select v-model="item.storehouseStartTime" size="small"
                                                        :picker-options="{start: index == 0 ? '09:00' : customRulesForm.ticketingStorehouseDetailVoList[index-1].storehouseEndTime,step: '00:30',end: '17:00',maxTime: item.storehouseEndTime}"
                                                        placeholder="开始时段" style="width: 100%"></el-time-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="1" class="text_center">~</el-col>
                                <el-col :span="5">
                                    <el-form-item label-width="0"
                                                  :prop="`ticketingStorehouseDetailVoList.${index}.storehouseEndTime`"
                                                  :rules="[{required: true, message: '结束时段不能为空', trigger: 'change'}]">
                                        <el-time-select v-model="item.storehouseEndTime" size="small"
                                                        :picker-options="{start: '09:00',step: '00:30',end: '17:00',minTime: item.storehouseStartTime}"
                                                        placeholder="结束时段" style="width: 100%"></el-time-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4" class="m_l1">
                                    <el-form-item label-width="0"
                                                  :prop="`ticketingStorehouseDetailVoList.${index}.countTicketing`"
                                                  :rules="[{required: true, message: '放票数量不能为空', trigger: 'blur'}]">
                                        <el-input size="small" v-model="item.countTicketing"
                                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                                                  @input="customTicketingChange(item)" placeholder="放票数量"
                                                  maxlength="5"></el-input>
                                    </el-form-item>

                                </el-col>
                                <el-col :span="3" class="m_l1">
                                    <el-button size="medium" type="text" icon="el-icon-minus"
                                               v-show="customRulesForm.ticketingStorehouseDetailVoList.length > 1"
                                               @click="delCustomRulesItem(index)"></el-button>
                                    <el-button size="medium" type="text" icon="el-icon-plus"
                                               v-show="customRulesForm.ticketingStorehouseDetailVoList.length < 10 && item.storehouseStartTime != '' &&  item.storehouseEndTime != '' && index == customRulesForm.ticketingStorehouseDetailVoList.length - 1"
                                               @click="addCustomRulesItem"></el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                    <div style="padding-left: 120px">
                        <div class="tips p_b2" v-show="customRulesIsEdit">
                            *修改数据时，已预约的票数作为一个单独的票仓存储。当日总票数=设置的票数+已预约票数。已预约数据保持不变。
                        </div>
                        <el-button size="small" type="primary" @click="editCustomRules" v-if="!customRulesIsEdit">编辑
                        </el-button>
                        <el-button size="small" type="primary" @click="submitCustomRules" v-noMoreClick v-else>提交
                        </el-button>
                        <el-button size="small" @click="cancelCustomRules" v-if="customRulesIsEdit">取消</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="query-form p_b2 closedRules">
            <div class="plateTit">闭馆规则</div>
            <el-row>
                <el-col :span="2" class="itemLabel">选择日期：</el-col>
                <el-col :span="21">
                    <el-col :span="24" class="flex_b_c">
                        <el-col :span="14" class="p_l2">
                            <el-calendar v-model="closedRulesCalendarVal">
                                <template slot="dateCell" slot-scope="{date, data}">
                                    <div @click.stop="data.type != 'prev-month' ? chooseClosedRulesDay(data):''"
                                         class="dayItem">
                                        <div class="closeIcon" v-if="isClosed(data)">闭</div>
                                        <div>{{ data.day.split('-').slice(2)[0] }}</div>
                                        <div v-for="(item, index) in formatClosedRules(data, 1)" :key="index">
                                            <div class="dayVotesNum" v-show="data.type == 'current-month'">
                                                {{item.outTicketing}}/{{item.countTicketing}}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-calendar>
                        </el-col>
                        <el-col :span="2" class="text_center" v-if="closedList.length > 0">
                            <el-button icon="el-icon-arrow-right" circle></el-button>
                        </el-col>
                        <el-col :span="8" class="b_d selectedDateBox" v-if="closedList.length > 0">
                            <div class="flex_b_c selectedTit">
                                <div>已选日期共 <span class="selTotal">{{this.closedList.length}}</span> 天</div>
                                <div class="el-icon-delete pointer" @click="emptyClosedDay">清空</div>
                            </div>
                            <div class="selectedDateList">
                                <div class="flex_b_c item" v-for="(item, index) in closedList" :key="index">
                                    <div>{{item}}</div>
                                    <div class="el-icon-close" @click="delSingleClosedDay(index)"></div>
                                </div>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="24" class="p_l2 p_t2">
                        <div>
                            <div class="p_r2 p_b1">导入日期</div>
                            <el-col :span="8">
                                <el-upload
                                        class="upload-demo" ref="upload"
                                        action="string" :limit="1"
                                        :http-request="uploadDate"
                                        :on-exceed="exceedFile" :on-remove="removeDateFile" :beforeUpload="beforeUpload"
                                        :on-change="fileChange" :file-list="importDateFile">
                                    <el-button size="small" type="primary">本地导入</el-button>
                                </el-upload>
                            </el-col>
                        </div>
                        <el-col :span="24" class="flex_l_c p_b2">
                            <div class="importTips">请根据模板格式导入文档，否则系统无法读取导入数据。</div>
                            <el-link size="small" type="primary" @click="templateDownload">模板下载</el-link>
                        </el-col>
                        <el-col :span="24" v-if="closedList.length > 0 || importDateList.length > 0">
                            <el-button size="small" type="primary" @click="openClosed(0)">闭馆</el-button>
                            <el-button size="small" type="primary" @click="openClosed(1)">开馆</el-button>
                        </el-col>
                    </el-col>
                </el-col>
            </el-row>
        </div>
        <!--闭馆开馆弹窗-->
        <el-dialog title="提示" width="40%" :close-on-click-modal="false" :visible.sync="visibleSwitch">
            <div class="text_center">是否确认已选日期{{openClosedType == 0 ? '闭':'开'}}馆？</div>
            <div class="p_t2">
                <div class="text_right">共 <span class="selTotal">{{closedList.length + importDateList.length}}</span> 天
                </div>
                <div class="dataListBox">
                    <div class="item" v-for="(item, index) in closedList" :key="index">{{item}}</div>
                    <div class="item" v-for="(item, index) in importDateList" :key="index">{{item}}</div>
                </div>
                <div v-if="openClosedType == 0">
                    <el-checkbox v-model="checkedInform">是否对已预约用户发送闭馆通知</el-checkbox>
                    <el-input type="textarea" :rows="2" placeholder="请输入通知内容" v-model.trim="informContent"
                              maxlength="20"
                              show-word-limit v-if="checkedInform"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visibleSwitch = false">再想想</el-button>
                <el-button type="primary" size="small" @click="confirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                // 基本设置
                basicSetForm: {
                    ticketingMakeDays: null,
                    ticketingOrderPeople: null,
                    ticketingCheckTime: null,
                    ticketingVisitorUserMax: null,
                    ticketingMakeNum: null,
                },
                ticketingMakeNum: null,
                makeNumOptions: [
                    {
                        label: '不限',
                        value: null
                    },
                    {
                        label: '1次',
                        value: 1
                    },
                    {
                        label: '自定义',
                        value: 2
                    }
                ],
                rules: {
                    ticketingMakeDays: [
                        {required: true, message: '请输入提前预约天数', trigger: 'change'}
                    ],
                    ticketingOrderPeople: [
                        {required: true, message: '请输入每个订单预约人数上限', trigger: 'change'}
                    ],
                    ticketingVisitorUserMax: [
                        {required: true, message: '请输入参观人添加数量', trigger: 'change'}
                    ],
                    /*ticketingMakeNum: [
                        {required: true, message: '请选择每人每天预约次数', trigger: 'change'}
                    ],*/
                    ticketingCheckTime: [
                        {required: true, message: '请选择预约核销', trigger: 'change'}
                    ],
                },
                // 放票规则
                pickerOptions: {
                    disabledDate(v) {
                        return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
                    }
                },
                defaultRulesIsEdit: false,
                defaultRulesForm: {
                    list: [],
                },
                customList: [],
                showCustomRules: false,
                customRulesIsEdit: false,
                customRulesForm: {
                    checkOperate: '0',
                    belongToDay: '',
                    ticketingStorehouseDetailVoList: [],
                },
                customRulesCalendarVal: new Date(),
                // 闭馆规则
                closedDateList: [],
                closedTicketList: [],
                closedRulesCalendarVal: new Date(),
                closedList: [],
                importDateList: [],
                importDateFile: [],
                openClosedType: null,
                visibleSwitch: false,
                checkedInform: false,
                informContent: '',
                limitPrev: new Date('2022-11-01'),  // 上个月最大限制
            }
        },
        mounted() {
            this.listenerCustomRulesMonth() // 监听月份选择
            this.getBasicSet() // 获取基本设置
            this.getDefaultRules() // 获取默认放票规则
            this.getDefaultRulesMonthTicket(this.customRulesCalendarVal, 0) // 指定月份获取票仓数据
            this.getDefaultRulesMonthTicket(this.closedRulesCalendarVal, 1) // 指定月份获取票仓数据
            this.getClosedData() // 获取闭馆数据
        },
        computed: {
            formatCustomRules() {
                return data => {
                    return this.customList.filter(item => {
                        let time = item.belongToDay;
                        return time == data.day;
                    });
                };
            },
            formatClosedRules() {
                return data => {
                    return this.closedTicketList.filter(item => {
                        let time = item.belongToDay;
                        return time == data.day;
                    });
                };
            }
        },
        methods: {
            // 获取基本设置
            getBasicSet() {
                this.$axios(this.api.ticketing.getByTicketingMakeConfig).then((res) => {
                    if (res.status) {
                        this.recover(this.basicSetForm, res.data);
                        if (this.basicSetForm.ticketingMakeNum > 1) {
                            this.ticketingMakeNum = res.data.ticketingMakeNum
                            this.$set(this.basicSetForm, 'ticketingMakeNum', 2)
                        }
                    }
                })
            },
            // 提交基本设置
            submitBasicSet() {
                if (this.basicSetForm.ticketingMakeNum == 2) {
                    if (this.ticketingMakeNum == null || this.ticketingMakeNum == '') {
                        this.$message('请输入自定义次数');
                        return false;
                    }
                }
                let prame = JSON.parse(JSON.stringify(this.basicSetForm))
                if (this.basicSetForm.ticketingMakeNum == 2 && this.ticketingMakeNum) {
                    prame.ticketingMakeNum = parseInt(this.ticketingMakeNum)
                }
                prame.ticketingMakePrice = 0
                this.$refs['basicSetForm'].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.ticketing.updateByTicketingMakeConfig, prame, 'put').then((res) => {
                            if (res.status) {
                                this.getBasicSet()
                                this.$message.success('提交成功');
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                })
            },
            // 每人每天预约次数改变
            changeTicketingMakeNum() {
                this.ticketingMakeNum = null
            },
            // 获取默认放票规则
            getDefaultRules() {
                this.$axios(this.api.ticketing.getTicketingStorehouseConfig).then((res) => {
                    if (res.status) {
                        this.defaultRulesForm.list = res.data
                        this.defaultRulesForm.list.map((item) => {
                            this.$set(item, 'startTime', item.startTime.slice(0, item.startTime.lastIndexOf(":")))
                            this.$set(item, 'endTime', item.endTime.slice(0, item.endTime.lastIndexOf(":")))
                        })
                    }
                })
            },
            // 提交默认放票规则
            submitDefaultRules() {
                let rulesData = JSON.parse(JSON.stringify(this.defaultRulesForm.list))
                rulesData.map(item => {
                    item.startTime = item.startTime + ':00'
                    item.endTime = item.endTime + ':00'
                })
                this.$refs['defaultRulesForm'].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.ticketing.saveTicketingStorehouseConfig, rulesData, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('提交成功');
                                this.getDefaultRules()
                                this.defaultRulesIsEdit = false
                            } else {
                                this.$message.error('提交失败');
                            }
                        })
                    }
                })
            },
            // 取消默认放票规则
            cancelDefaultRules() {
                this.getDefaultRules()
                this.defaultRulesIsEdit = false
            },
            // 修改默认放票规则
            editDefaultRules() {
                this.defaultRulesIsEdit = true;
            },
            // 添加默认放票规则项
            addDefaultRulesItem() {
                this.defaultRulesForm.list.push({
                    startTime: '',
                    endTime: '',
                    vote: '',
                })
            },
            // 删除默认放票规则项
            delDefaultRulesItem(index) {
                this.defaultRulesForm.list.splice(index, 1);
            },
            // 切换默认、自定义
            checkOperateChange(data) {
                if (data == 1 && this.customRulesForm.ticketingStorehouseDetailVoList.length == 0) {
                    this.addCustomRulesItem()
                }
            },
            // 默认放票规则放票数量修改
            countTicketingChange(item) {
                let value = item.countTicketing.replace(/[^\d]/g, '')
                if (value < 1) {
                    this.$set(item, 'countTicketing', 1)
                } else {
                    this.$set(item, 'countTicketing', value)
                }
            },
            // 指定月份获取票仓数据
            getDefaultRulesMonthTicket(val, type) {
                let dayStr = this.common.formatMonthDate(val)
                this.$axios(this.api.ticketing.getByYearsTicketingStorehouse, {dayStr}).then((res) => {
                    if (res.status) {
                        if (type == 0) {
                            this.customList = res.data
                        } else {
                            this.closedTicketList = res.data
                        }
                    }
                })
            },
            // 选择自定义放票规则-天
            chooseCustomRulesDay(date) {
                this.customRulesCalendarVal = new Date(date.day)
                this.customRulesIsEdit = false;
                this.$axios(this.api.ticketing.getByDayTicketingStorehouse, {belongToDay: date.day}).then((res) => {
                    if (res.status) {
                        if (res.data) {
                            this.customRulesForm = res.data
                            this.$set(this.customRulesForm, 'belongToDay', [this.customRulesForm.belongToDay, this.customRulesForm.belongToDay])
                            this.customRulesForm.ticketingStorehouseDetailVoList.map((item) => {
                                this.$set(item, 'storehouseStartTime', item.storehouseStartTime.slice(0, item.storehouseStartTime.lastIndexOf(":")))
                                this.$set(item, 'storehouseEndTime', item.storehouseEndTime.slice(0, item.storehouseEndTime.lastIndexOf(":")))
                            })
                            this.showCustomRules = true;
                        } else {
                            // 选择日期小于今天
                            if (new Date(date.day) < new Date()) {
                                this.$message('没有历史数据哦');
                            } else {
                                this.customRulesForm = {
                                    checkOperate: '0',
                                    belongToDay: [date.day, date.day],
                                    ticketingStorehouseDetailVoList: [],
                                }
                                this.showCustomRules = true;
                            }
                        }
                    }
                })
            },
            // 自定义放票规则放票数量修改
            customTicketingChange(item) {
                let value = item.countTicketing.replace(/[^\d]/g, '')
                if (value < 1) {
                    this.$set(item, 'countTicketing', 1)
                } else {
                    this.$set(item, 'countTicketing', value)
                }
            },
            // 修改自定义放票规则
            editCustomRules() {
                this.customRulesIsEdit = true
                if (this.customRulesForm.checkOperate == 1) {
                    if (this.customRulesForm.ticketingStorehouseDetailVoList.length == 0) {
                        this.customRulesForm.ticketingStorehouseDetailVoList = [
                            {
                                storehouseStartTime: '',
                                storehouseEndTime: '',
                                countTicketing: '',
                            }
                        ]
                    }
                }
            },
            // 添加自定义放票规则项
            addCustomRulesItem() {
                this.customRulesForm.ticketingStorehouseDetailVoList.push({
                    storehouseStartTime: '',
                    storehouseEndTime: '',
                    countTicketing: '',
                })
            },
            // 删除自定义放票规则项
            delCustomRulesItem(index) {
                this.customRulesForm.ticketingStorehouseDetailVoList.splice(index, 1);
            },
            // 取消自定义放票规则
            cancelCustomRules() {
                this.showCustomRules = false;
            },
            // 提交自定义放票规则
            submitCustomRules() {
                this.$refs['customRulesForm'].validate((valid) => {
                    if (valid) {
                        let apiName = ''
                        let rulesData = {
                            belongToDayStart: this.customRulesForm.belongToDay[0],
                            belongToDayEnd: this.customRulesForm.belongToDay[1],
                        }
                        if (this.customRulesForm.checkOperate == 0) {
                            // 默认票仓
                            apiName = 'restoreDefaultTicketingStorehouse'
                        } else {
                            // 自定义票仓
                            apiName = 'buildDiyTicketingStorehouse'
                            let list = JSON.parse(JSON.stringify(this.customRulesForm.ticketingStorehouseDetailVoList))
                            list.map(item => {
                                item.storehouseStartTime = item.storehouseStartTime + ':00'
                                item.storehouseEndTime = item.storehouseEndTime + ':00'
                            })
                            rulesData.ticketingStorehouseDetail = list
                        }
                        this.$axios(this.api.ticketing[apiName], rulesData, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('提交成功');
                                this.getDefaultRulesMonthTicket(this.customRulesCalendarVal, 0)
                                this.customRulesIsEdit = false;
                            } else {
                                this.$message.error('提交失败');
                            }
                        })
                    }
                })
            },
            // 监听自定义放票规则月份选择
            listenerCustomRulesMonth() {
                let that = this;
                let newHandle = function (e, type) {
                    e.stopPropagation();
                    if (that.customRulesCalendarVal <= that.limitPrev) {
                        $('.el-button--mini:first-child').attr('disabled', true)
                        that.$message('到达历史数据上限');
                        return false
                    } else {
                        $('.el-button--mini:first-child').attr('disabled', false)
                    }
                    if (type == 0) {
                        that.getDefaultRulesMonthTicket(that.customRulesCalendarVal, type)
                    } else {
                        that.getClosedData() // 获取闭馆数据
                        that.getDefaultRulesMonthTicket(that.closedRulesCalendarVal, type)
                    }
                };
                $(".el-button--mini:not(:first-child):not(:last-child) span").html("当月")
                //自定义放票规则按钮
                $('.customRules .el-button--mini:first-child, .customRules .el-button--mini:not(:first-child):not(:last-child), .customRules .el-button--mini:last-child').click(function (e) {
                    newHandle(e, 0);
                })
                //闭馆规则按钮
                $('.closedRules .el-button--mini:first-child, .closedRules .el-button--mini:not(:first-child):not(:last-child), .closedRules .el-button--mini:last-child').click(function (e) {
                    newHandle(e, 1);
                })
            },
            // 获取闭馆数据
            getClosedData() {
                let closeDayStr = this.common.formatMonthDate(this.closedRulesCalendarVal)
                this.$axios(this.api.ticketing.getByYearsTicketingClose, {closeDayStr}).then((res) => {
                    if (res.status) {
                        this.closedDateList = res.data
                    }
                })
            },
            // 模板下载
            templateDownload() {
                window.open(this.$globalUrl() + this.api.ticketing.downLoadTempExcel, "_blank");
            },
            // 是否闭馆
            isClosed(date) {
                if (this.closedDateList.filter(item => item == date.day).length > 0) {
                    return true
                } else {
                    return false
                }
            },
            // 选择闭馆日期
            chooseClosedRulesDay(date) {
                this.closedRulesCalendarVal = new Date(date.day)
                if (this.closedList.filter(item => item == date.day).length > 0) {
                    this.$message('已选择当前日期');
                } else {
                    this.closedList.push(date.day)
                }
            },
            // 清空闭馆日期
            emptyClosedDay() {
                this.closedList = []
                this.closedRulesCalendarVal = new Date()
            },
            // 删除单个闭馆日期
            delSingleClosedDay(index) {
                this.closedList.splice(index, 1);
                this.closedRulesCalendarVal = new Date()
            },
            // 闭/开馆 type 0闭，1开
            openClosed(type) {
                this.openClosedType = type
                this.checkedInform = false
                this.informContent = ''
                this.visibleSwitch = true
            },
            // 确定闭/开馆
            confirm() {
                let apiName = '', param = {}
                if (this.openClosedType == 0) {
                    apiName = 'saveTicketingClose'
                    param = {
                        closeString: this.closedList.concat(this.importDateList),
                    }
                    if (this.informContent == '') {
                        param.reason = null
                    } else {
                        param.reason = this.informContent
                    }
                } else {
                    apiName = 'saveOpenTicketing'
                    param = this.closedList.concat(this.importDateList)
                }
                this.$axios(this.api.ticketing[apiName], param, 'post').then((res) => {
                    if (res.status) {
                        this.$message.success('提交成功');
                        this.visibleSwitch = false
                        this.checkedInform = false
                        this.informContent = ''
                        this.closedList = []
                        this.importDateFile = []
                        this.importDateList = []
                        this.getClosedData()
                    } else {
                        this.$message.error('提交失败');
                    }
                })
            },
            // 导入日期
            uploadDate(param) {
                const formData = new FormData()
                formData.append('file', param.file)
                this.$axios(this.api.ticketing.importTicketingclose, formData, 'post').then((res) => {
                    if (res.status) {
                        this.importDateList = res.data
                        this.$message.success('上传成功');
                        param.onSuccess()
                    } else {
                        this.$message.error('解析错误，请核对模板后重新上传');
                        this.importDateFile = []
                        param.onError()
                    }
                })
            },
            // 上传文件改变
            fileChange(file) {
                this.$refs.upload.clearFiles() //清除文件对象
                this.importDateFile = [{name: file.name, url: file.url}] // 重新手动赋值filstList， 免得自定义上传成功了, 而fileList并没有动态改变， 这样每次都是上传一个对象
            },
            // 文件移除
            removeDateFile() {
                this.importDateList = [];
                this.importDateFile = [];
            },
            // 文件超出个数限制时的钩子
            exceedFile() {
                if (this.importDateFile.length > 0) {
                    this.$message.error("只能导入1个文件");
                }
            },
            // 限制文件大小跟格式
            beforeUpload(file) {
                let fileType = ['xlsx', 'xls']
                let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
                let testType = Boolean(fileType.find(item => item == testmsg) == testmsg)
                if (!testType) {
                    this.$message.error(`上传文件只能是${fileType.join('、')}格式!`);
                    this.removeDateFile()
                    return false
                }
                const isLt1M = file.size / 1024 / 1024 < 1
                if (!isLt1M) {
                    this.$message.error('上传文件大小不能超过 1MB!')
                    this.removeDateFile()
                    return false
                }
            },
        }
    }
</script>

<style scoped>

</style>
